/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Form, Button, Header, Grid, Segment } from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX } from 'shared/constants';
import { EmailService } from 'services/EmailService';

import { ContactFormContainer } from './styled';

export type UserFormData = {
  clientName: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
};

const ContactForm = () => {
  const { register, setValue, handleSubmit, errors, triggerValidation } = useForm<UserFormData>();
  const [loading, setLoading] = useState(false);
  const hasErrors = Object.keys(errors).length > 0;

  useEffect(() => {
    register({ name: 'clientName' }, { required: true });
    register({ name: 'subject' }, { required: true });
    register({ name: 'message' }, { required: true });
    register(
      { name: 'email' },
      {
        required: true,
        pattern: EMAIL_REGEX,
      },
    );
    register({ name: 'phone' }, { required: true });
  }, [register]);

  const onSubmit = handleSubmit(async (userData: UserFormData, e: any) => {
    try {
      setLoading(true);
      await EmailService.SendEmail({
        email: userData.email,
        name: userData.clientName,
        phone: userData.phone,
        message: userData.message,
        subject: userData.subject,
      });
      // window.location.href = `mailto:tbenim@amedicarx.com?subject=${encodeURI(
      //   userData.subject,
      // )}&body=${encodeURI(
      //   'Name: ' +
      //     userData.clientName +
      //     '\n' +
      //     'Email: ' +
      //     userData.email +
      //     '\n' +
      //     'Phone: ' +
      //     userData.phone +
      //     '\n' +
      //     'Message: ' +
      //     userData.message,
      // )}`;
      toast.success('Check your email client!');
      setLoading(false);
      e.target.reset();
      // Object.keys(userData).map((key) => setValue(key, '', false));
    } catch (error) {
      console.log(error);
      toast.error('Sorry we try again later');
      setLoading(false);
    }
  });

  const onFormValueChanged = async (_: any, { name, value }: any) => {
    setValue(name, value, true);
    await triggerValidation(name);
  };

  return (
    <ContactFormContainer>
      <Segment loading={loading}>
        <div className='formContainer'>
          <Header as='h2'>Request a Demo</Header>
          <Form onSubmit={onSubmit}>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column computer={8} mobile={16}>
                  <Form.Input
                    error={errors.clientName != null}
                    onChange={onFormValueChanged}
                    name='clientName'
                    placeholder='Name'
                  />
                </Grid.Column>
                <Grid.Column computer={8} mobile={16}>
                  <Form.Input
                    error={errors.email != null}
                    onChange={onFormValueChanged}
                    name='email'
                    type='email'
                    placeholder='Email'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column computer={8} mobile={16}>
                  <Form.Input
                    error={errors.phone != null}
                    onChange={onFormValueChanged}
                    name='phone'
                    maxLength={10}
                    type='tel'
                    placeholder='Phone'
                  />
                </Grid.Column>
                <Grid.Column computer={8} mobile={16}>
                  <Form.Input
                    error={errors.subject != null}
                    onChange={onFormValueChanged}
                    name='subject'
                    placeholder='Subject'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.TextArea
                    error={errors.message != null}
                    rows={6}
                    fluid
                    placeholder='Message'
                    type='text'
                    name='message'
                    onChange={onFormValueChanged}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {hasErrors && <p>Please check that the fields are entered correctly.</p>}
            <Button color='black' fluid type='submit'>
              Submit
            </Button>
          </Form>
        </div>
      </Segment>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </ContactFormContainer>
  );
};

export default ContactForm;
