import styled from 'styled-components';
import { colors } from 'shared/theme';

 
export const FrequentlyAskedQuestions = styled.div`
  position: relative;
  width: 100%;
  background: #fbfbfb;
  border-radius: 20px;
  padding: 40px;
  .sub.header.primary-c {
    max-width: 400px;
    margin: 20px auto !important;
    align-self: center;
  }
  .sub.header.primary-s {
    max-width: 520px;
    margin: 20px auto !important;
    align-self: center;
  }
  h2.ui.header {
    text-align: center;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: -0.01em;
    color: ${colors.basicText};
  }
  h3.ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: ${colors.basicText};
    margin-bottom: 15px;
  }
  h3.ui.header .sub.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-top: 15px;
  }
  .ui.grid {
    /* margin-bottom: 180px; */
  }
  .ui.header > .image:not(.icon),
  .ui.header > img {
    margin-bottom: 30px;
    width: 240px;
  }

  img.ui.image.partners-mobile {
    display: none;
  }

  @media (max-width: 767px) {
    img.ui.image.partners {
      display: none;
    }
    img.ui.image.partners-mobile {
      display: block;
      width: 100%;
      margin-top: 50px;
    }
  }
`;
 