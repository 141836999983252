import React, { useContext, useState, useEffect, useRef } from 'react';
import { SearchBarContainer } from './styled';
import { Header, Search, Grid, Input, Button, Icon, SearchProps, Divider } from 'semantic-ui-react';
import { debounce, uniqueId } from 'lodash';
import LocationIcon from 'assets/icons/location.svg';
import SearchIcon from 'assets/icons/searchBlue.svg';
import { AppContext } from 'containers/App/context';
import { SearchService } from 'services/SearchService';
import useKeypress from 'shared/Hooks/useKeypress';
import { toast, ToastContainer } from 'react-toastify';

const SearchBar = () => {
  const {
    setSearchedMedicationID,
    setSearchedZipCode,
    searchedMedicationName,
    searchedZipCode,
    setShowSearchDetails,
    showSearchDetails,
    setMedicationDetails,
    medicationDetails,
    setSearchedMedicationName,
    userWebPage,
  } = useContext<any>(AppContext);
  const [searchResults, setSearchResults] = useState([]);
  const [loadingResults, setLoadingResults] = useState(false);
  const [selectedResult, setSelectedResult] = useState({ title: '' });
  const [searchedTextError, setSearchedTextError] = useState(false);
  const [zipTextError, setZipTextError] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const searchInputRef = useRef<any>();
  const zipInputRef = useRef<any>();

  const cleanSearch = () => {
    setSearchResults([]);
    setSelectedResult({ title: '' });
    setSearchedText('');
  };

  useEffect(() => {
    if (!medicationDetails.MedicationID) {
      setSearchedZipCode('');
      cleanSearch();
    }
  }, [setSearchedZipCode, medicationDetails.MedicationID]);

  const handleResultSelect = (_: any, { result }: SearchProps) => {
    console.log('*********************');
    console.log(result);
    setSearchedTextError(false);
    setSelectedResult(result);
    setSearchedMedicationID(result.id);
    setSearchedMedicationName(result.SeoName);
  };

  const handleSearchChange = async (event: any, { value }: any) => {
    setSearchedTextError(false);
    if (
      event.nativeEvent.inputType === 'deleteContentBackward' ||
      (event.nativeEvent.inputType === 'insertText' && selectedResult.title !== '')
    ) {
      if (selectedResult.title !== '') {
        cleanSearch();
      }
    }
    setSearchedText(value);
    if (value.length < 3) {
      return;
    }
    setLoadingResults(true);
    const {
      data: { DrugNames },
    } = await SearchService.SearchMedication({
      s: value,
      apiDiff: userWebPage.savingsCard.apiDiff,
    });
    setLoadingResults(false);
    console.log(DrugNames);
    setSearchResults(
      DrugNames.map((r: any) => ({
        key: uniqueId('medi-'),
        id: uniqueId('medi-id'),
        title: r.DrugName,
        Ndc: r.Ndc,
        SeoName: r.SeoName,
        // description: r.DrugName,
      })),
    );
  };

  const handleZipCodeChange = async (_: any, { value }: any) => {
    setZipTextError(false);
    setSearchedZipCode(value.trim());
  };

  const validateinputs = () => {
    let valid = true;
    if (searchedZipCode === '') {
      setZipTextError(true);
      zipInputRef.current.focus();
      valid = false;
    }

    if (selectedResult.title === '') {
      setSearchedTextError(true);
      searchInputRef.current.focus();
      valid = false;
    }

    return valid;
  };

  const onSubmitQuery = async () => {
    if (validateinputs()) {
      setLoadingResults(true);
      console.log(searchedMedicationName);
      const { data } = await SearchService.GetDiscountCardDetail({
        drugName: searchedMedicationName,
        zip: searchedZipCode,
        apiDiff: userWebPage.savingsCard.apiDiff,
      });
      setLoadingResults(false);
      // if (data?.Drugs?.length > 0 && data.DrugInfo != null) {
      if (data?.Drugs?.length > 0) {
        setMedicationDetails(data);
        setShowSearchDetails(true);
      } else {
        toast.error(
          'Error getting your drug data, please try a different drug name or brand. Please contact us if this persists.',
        );
      }
    } else {
      console.log('EV');
    }
  };

  useKeypress('Enter', () => {
    onSubmitQuery();
  });

  return (
    <SearchBarContainer showSearchDetails={showSearchDetails}>
      <Header as='h1'>
        {userWebPage.title1} <br /> {userWebPage.title2}
      </Header>
      <Grid columns={3}>
        <Grid.Column verticalAlign={'middle'} mobile={16} tablet={8} computer={8}>
          <Search
            minCharacters={3}
            loading={loadingResults}
            className='icon-input'
            onResultSelect={handleResultSelect}
            onSearchChange={debounce(handleSearchChange, 500, {
              leading: true,
            })}
            results={searchResults}
            input={{
              error: searchedTextError,
              icon: <Icon as='img' src={SearchIcon} />,
              iconPosition: 'left',
              ref: searchInputRef,
              placeholder: 'Drug Search',
              value: selectedResult.title || searchedText,
            }}
          />
          <Divider inverted />
        </Grid.Column>
        <Grid.Column
          className='zip-column'
          verticalAlign={'middle'}
          mobile={16}
          tablet={8}
          computer={4}
        >
          <Input
            onChange={handleZipCodeChange}
            error={zipTextError}
            className='icon-input'
            iconPosition='left'
            placeholder='Zip code'
            value={searchedZipCode}
            ref={zipInputRef}
            type='tel'
            icon={<Icon as='img' src={LocationIcon} />}
          ></Input>
        </Grid.Column>

        <Grid.Column verticalAlign={'middle'} mobile={16} tablet={16} computer={4}>
          <Button
            loading={loadingResults}
            onClick={onSubmitQuery}
            style={{ backgroundColor: userWebPage.actionButtonColor }}
            color='orange'
          >
            DISCOVER SAVINGS
          </Button>
        </Grid.Column>
      </Grid>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </SearchBarContainer>
  );
};

export default SearchBar;
