import React, { useState, useContext, useRef } from 'react';
import { HeaderContainer } from './styled';
import { Menu, Image, Header, Grid, Button } from 'semantic-ui-react';
import amedicaRXIso from 'assets/logos/amedicarx_iso.svg';
import HeaderArt from 'assets/icons/providerPortalMock.svg';
import { AppContext } from 'containers/App/context';
import SearchBar from 'components/SearchBar';
import { isMobileDevice } from 'shared/utils';
import Rodal from 'rodal';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';

const scrollToRef = (ref: any) =>
  window.scrollBy({
    top: ref.current.offsetTop + 40,
    left: 0,
    behavior: 'smooth',
  });

const CHeader = () => {
  const { setShowSearchDetails, setMedicationDetails, showSearchDetails } = useContext(AppContext);
  const myRef = useRef(null);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const shareUrl = 'https://amedicarx.com/';
  const SMSEncoded = 'Amedicarx.com%20-%20Save%20on%20meds!';
  let SMSAction = `mms:?body=${SMSEncoded}`;
  if (isMobileDevice.iOS()) {
    SMSAction = `sms:&body=${SMSEncoded}`;
  }

  const title = 'AmedicaRx - Shop Prices, Save on Meds';
  const iconSize = 40;

  const onBackToHome = () => {
    setMedicationDetails({});
    setShowSearchDetails(false);
  };

  const onShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'AmedicaRx.co,',
          text: title,
          url: shareUrl,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      setShowShareDialog(true);
    }
  };

  const renderCommanHeader = () => {
    return (
      <HeaderContainer>
        <Menu borderless>
          <Menu.Item onClick={onBackToHome} position='left'>
            <Image src={amedicaRXIso} as='a' href='/' />
          </Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item fitted>
              <Button
                as='a'
                href='https://provider.amedicarx.com/login'
                compact
                basic
                fluid
                color='blue'
              >
                Log in
              </Button>
            </Menu.Item>
            <Menu.Item fitted>
              <Button
                as='a'
                href='https://provider.amedicarx.com/sign-up'
                color='blue'
                compact
                fluid
              >
                Sign Up
              </Button>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        {(isMobileDevice.android() || isMobileDevice.iOS()) && (
          <div className='share-btn'>
            <Button as='a' basic color='blue' content='Share' icon='share' onClick={onShare} />
          </div>
        )}
        <SearchBar />
        <Rodal visible={showShareDialog} onClose={() => setShowShareDialog(false)}>
          <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} title={title}>
            <TwitterIcon size={iconSize} round />
          </TwitterShareButton>
          <Button
            className='sms-action'
            circular
            as='a'
            href={SMSAction}
            color='facebook'
            icon='chat'
          />
          <WhatsappShareButton url={shareUrl} title={title} separator=':: '>
            <WhatsappIcon size={iconSize} round />
          </WhatsappShareButton>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={iconSize} round />
          </LinkedinShareButton>
          <EmailShareButton url={shareUrl} subject={title} body='Great savings here!'>
            <EmailIcon size={iconSize} round />
          </EmailShareButton>
        </Rodal>
        {!showSearchDetails && (
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column width={6} only='mobile'>
                <Image src={HeaderArt} fluid />
              </Grid.Column>
              <Grid.Column width={10}>
                <Grid>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Header as='h1'>
                        Improve <strong style={{ color: '#1C5CFD' }}>medication adherence</strong>{' '}
                        while saving money for your patients and employees
                      </Header>
                      <p style={{ marginTop: '1rem' }}>
                        AmedicaRx uses private labeled prescription savings programs and targeted
                        messaging campaigns to improve lives and save money at
                        <strong style={{ color: '#1C5CFD' }}> little to no cost</strong>
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column mobile={16} computer={5}>
                      <Button
                        as='a'
                        href='https://provider.amedicarx.com/sign-up'
                        target='_blank'
                        fluid
                        color='blue'
                      >
                        Get started
                      </Button>
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={5}>
                      <Button onClick={() => scrollToRef(myRef)} basic color='blue' fluid>
                        Read more
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={6} only='computer tablet'>
                <Image src={HeaderArt} fluid />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <div ref={myRef} />
      </HeaderContainer>
    );
  };

  const renderFAQHeader = () => {
    return (
      <HeaderContainer>
        <Menu borderless>
          <Menu.Item onClick={onBackToHome} position='left'>
            <Image src={amedicaRXIso} as='a' href='/' />
          </Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item fitted>
              <Button
                as='a'
                href='https://provider.amedicarx.com/login'
                compact
                basic
                fluid
                color='blue'
              >
                Log in
              </Button>
            </Menu.Item>
            <Menu.Item fitted>
              <Button
                as='a'
                href='https://provider.amedicarx.com/sign-up'
                color='blue'
                compact
                fluid
              >
                Sign Up
              </Button>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        {(isMobileDevice.android() || isMobileDevice.iOS()) && (
          <div className='share-btn'>
            <Button as='a' basic color='blue' content='Share' icon='share' onClick={onShare} />
          </div>
        )}
        <Rodal visible={showShareDialog} onClose={() => setShowShareDialog(false)}>
          <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} title={title}>
            <TwitterIcon size={iconSize} round />
          </TwitterShareButton>
          <Button
            className='sms-action'
            circular
            as='a'
            href={SMSAction}
            color='facebook'
            icon='chat'
          />
          <WhatsappShareButton url={shareUrl} title={title} separator=':: '>
            <WhatsappIcon size={iconSize} round />
          </WhatsappShareButton>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={iconSize} round />
          </LinkedinShareButton>
          <EmailShareButton url={shareUrl} subject={title} body='Great savings here!'>
            <EmailIcon size={iconSize} round />
          </EmailShareButton>
        </Rodal>
      </HeaderContainer>
    );
  };

  const renderHeader = () => {
    if (window.location.pathname.includes('faq')) {
      return renderFAQHeader();
    }
    return renderCommanHeader();
  };
  return renderHeader();
};
export default CHeader;
