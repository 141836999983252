import React from 'react';
import { DiscountCardItem } from 'services/SearchService';
import { Grid, Header } from 'semantic-ui-react';
import { UserFormData } from '.';
import { PrescriptionCardType } from 'containers/App/context';
import { SavingCardTemplateContainer } from 'components/HowItWorks/styled';
import DiscountCardBackTemplate from 'components/DiscountCardBackTemplate';
import DiscountCardFrontTemplate from 'components/DiscountCardFrontTemplate';
import { defaultLogo } from 'shared/constants';

interface ComponentToPrintProps {
  rxGroup: string;
  rxBIN: string;
  selectedCard?: DiscountCardItem;
  medicationName: string;
  userData?: UserFormData;
  savingsCardStyles: PrescriptionCardType;
  subDomainName: string;
  userWebPage?: any;
}

class ComponentToPrint extends React.Component<ComponentToPrintProps> {
  render() {
    const {
      // rxBIN,
      rxGroup,
      selectedCard,
      medicationName,
      userData,
      savingsCardStyles,
      subDomainName,
    } = this.props;
    const currentDate = new Date();

    console.log('Address ', selectedCard?.Address);
    return (
      <Grid className='print-section'>
        <Grid.Row columns={2}>
          <Grid.Column className='print-card-container' mobile={16} tablet={8} computer={8}>
            <SavingCardTemplateContainer>
              <DiscountCardFrontTemplate
                logoBase64={defaultLogo}
                title={savingsCardStyles.savingCardTitle}
                backgroundFillColor={savingsCardStyles.backgroundColor}
                detailFillColor={savingsCardStyles.secundaryColor}
                lightTextColor={savingsCardStyles.lightTextColor}
                strongTextColor={savingsCardStyles.strongTextColor}
                // memberName={`${userData?.firstName} ${userData?.lastName}`}
                GRP={rxGroup}
                PCN={'SC1'}
                BIN={'610378'}
                subdomain={subDomainName}
              />
              <DiscountCardBackTemplate
                logoBase64={defaultLogo}
                title={savingsCardStyles.savingCardTitle}
                backgroundFillColor={savingsCardStyles.backgroundColor}
                detailFillColor={savingsCardStyles.secundaryColor}
                lightTextColor={savingsCardStyles.lightTextColor}
                strongTextColor={savingsCardStyles.strongTextColor}
                memberName={`${userData?.firstName} ${userData?.lastName}`}
                GRP={rxGroup}
                PCN={'SC1'}
                BIN={'610378'}
                subdomain={subDomainName}
              ></DiscountCardBackTemplate>
            </SavingCardTemplateContainer>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          {/* <Grid.Column mobile={16} tablet={8} computer={8}>
            {DisclaimerContent()}
          </Grid.Column> */}
        </Grid.Row>
        <Grid.Row className='print-pharmacy-details' columns={1}>
          <Grid.Column>
            <Header as='h2'>Pharmacy Details</Header>
            <p>
              Estimated price for your selected medications as of {currentDate.toLocaleDateString()}
            </p>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column textAlign='right'>
                  <Header as='h3'>Drug Name :</Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h3' style={{ opacity: 0.5 }}>
                    {medicationName}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column textAlign='right'>
                  <Header as='h3'>Pharmacy Name :</Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h3' style={{ opacity: 0.5 }}>
                    {selectedCard?.PharmacyName}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column textAlign='right'>
                  <Header as='h3'>Address :</Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h3' style={{ opacity: 0.5 }}>
                    {/* {`${selectedCard?.Address}, ${selectedCard?.City}, ${selectedCard?.State}`} */}
                    {`${selectedCard?.Address.split(',').slice(0, 4)}`}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column textAlign='right'>
                  <Header as='h3'>Contact Number :</Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h3' style={{ opacity: 0.5 }}>
                    {/* {selectedCard?.Phone} */}
                    (833) 901-4747
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column textAlign='right'>
                  <Header as='h3'>Price :</Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h3' style={{ opacity: 0.5 }}>
                    $ {selectedCard ? parseFloat(selectedCard?.Price).toFixed(2).toString() : ''}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  defaultProps = {
    rxGroup: '',
    rxBIN: '',
  };
}

export default ComponentToPrint;
