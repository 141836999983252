/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect, SyntheticEvent, useContext } from 'react';
import Rodal from 'rodal';
import {
  Form,
  Button,
  Transition,
  Header,
  Grid,
  InputOnChangeData,
  DropdownProps,
  Responsive,
  Segment,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';
import { ModalContainer } from './styled';
import { DiscountCardItem, SearchService } from 'services/SearchService';
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint from './ComponentToPrint';
import { useForm } from 'react-hook-form';
import { defaultLogo, EMAIL_REGEX, USA_STATES_LIST } from 'shared/constants';
import { normalizeOption } from 'shared/utils';
import { AppContext, UserWebPageType } from 'containers/App/context';
import DiscountCardFrontTemplate from 'components/DiscountCardFrontTemplate';
import DiscountCardFrontTemplateForDownload from 'components/DiscountCardFrontTemplateForDownload';
// import * as htmlToImage from 'html-to-image';
// import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
// import rasterizehtml from 'rasterizehtml';

export type UserFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  state: string;
};

type GetCardModalTypes = {
  rxGroup: string;
  rxBIN: string;
  selectedCard?: DiscountCardItem;
  show: boolean;
  onClose: () => void;
  medicationFullName: string;
  userWebPage: UserWebPageType;
};

const options = USA_STATES_LIST.map(({ name }: { name: string }) => normalizeOption(name, name));

export const DisclaimerContent = () => (
  <>
    <Header as='h3'>This is not insurance</Header>
    <p>
      Discounts are available exclusively through participating pharmacies. The range of the
      discounts will vary depending on the prescription and the pharmacy chosen. This program does
      not make payments directly to pharmacies. Members are required to pay for all prescription
      purchases. You may cancel your registration at any time or file a complaint by contacting
      Customer Care.
    </p>
    {/* <Header as='h4'>Pharmacy Help Desk (800) 404-1031</Header> */}
  </>
);

const GetCardModal = ({
  show,
  onClose,
  rxGroup,
  rxBIN,
  selectedCard,
  medicationFullName,
  userWebPage,
}: GetCardModalTypes) => {
  const { searchedMedicationName } = useContext<any>(AppContext);
  const [showGetCardOptions, setShowGetCardOptions] = useState(false);
  const [showPrintCard, setShowPrintCard] = useState(false);
  const [showDownloadCard, setShowDownloadCard] = useState(false);
  const [sendingDiscountCard, setSendingDiscountCard] = useState({ loading: false, isText: false });
  const { register, setValue, handleSubmit, errors, triggerValidation } = useForm<UserFormData>();
  const [userData, setUserData] = useState<UserFormData>();
  const hasErrors = Object.keys(errors).length > 0;
  const toPrintComponentRef = useRef<any>();

  const imageRef: any = useRef(null);
  const canvasRef: any = useRef(null);
  const { savingsCard } = userWebPage;
  // const resizeImage = async (base64Image: any) => {
  //   const img = new Image();
  //   img.src = base64Image;

  //   await img.onload;
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext('2d');

  //   // Calculate the new dimensions while maintaining aspect ratio
  //   // const aspectRatio = img.width / img.height;
  //   const finalWidth = 530;
  //   const finalHeight = 350;

  //   // Set canvas dimensions to the new size
  //   canvas.width = finalWidth;
  //   canvas.height = finalHeight;
  //   // context.fillStyle = 'white';
  //   // context.fillRect(0, 0, canvas.width, canvas.height);
  //   // Draw the image onto the canvas with the new dimensions
  //   context.drawImage(img, 0, 0, finalWidth, finalHeight);

  //   // Convert the canvas content back to base64
  //   const resizedBase64 = canvas.toDataURL('image/png');
  //   console.log(resizedBase64);
  //   return resizedBase64;
  //   // const link = document.createElement('a');
  //   // link.download = 'card.png';
  //   // link.href = resizedBase64;
  //   // link.click();
  //   // setShowDownloadCard(false);
  // };

  const downloadImage = async () => {
    setShowDownloadCard(true);
    domtoimage
      .toPng(imageRef.current, { quality: 100 })
      .then(async (dataUrl: any) => {
        console.log(dataUrl);
        // const response = await resizeImage(dataUrl);
        const link = document.createElement('a');
        link.download = 'card.png';
        link.href = dataUrl;
        link.click();
        setShowDownloadCard(false);
      })
      .catch((error: any) => {
        console.error('Error capturing image:', error);
      });
    //console.log(canvas.toDataURL());
  };

  const handlePrint = useReactToPrint({
    content: () => toPrintComponentRef.current,
  });

  const handlerPrintClick = () => {
    setShowPrintCard(true);
    setTimeout(() => {
      setShowPrintCard(false);
      handlePrint();
    }, 1000);
  };

  useEffect(() => {
    register({ name: 'firstName' }, { required: true });
    register({ name: 'lastName' }, { required: true });
    register({ name: 'state' }, { required: true });
    register(
      { name: 'email' },
      {
        required: true,
        pattern: EMAIL_REGEX,
      },
    );
    register({ name: 'phone' }, { required: true });
  }, [register]);

  const onSubmit = handleSubmit((userData: UserFormData) => {
    setUserData(userData);
    setShowGetCardOptions(true);
  });

  const onModalClose = () => {
    setShowGetCardOptions(false);
    onClose();
  };

  const onFormValueChanged = async (_: SyntheticEvent, { name, value }: InputOnChangeData) => {
    setValue(name, value, true);
    await triggerValidation(name);
  };

  const onStateChanged = async (_event: SyntheticEvent, { value, name }: DropdownProps) => {
    setValue(name, value, true);
    await triggerValidation(name);
  };

  const onSendDiscountCard = async (isText = false) => {
    setSendingDiscountCard({ isText, loading: true });
    domtoimage
      .toPng(imageRef.current, { quality: 100 })
      .then(async (dataUrl: any) => {
        console.log(dataUrl);
        // const response = await resizeImage(dataUrl);
        // const canvas = await htmlToImage.toCanvas(imageRef.current);
        const imgData = dataUrl.split(',')[1];
        const memberId: string | undefined = '12345678910';
        try {
          if (isText) {
            await SearchService.SendCardText({
              name: userData?.firstName || '',
              memberId: savingsCard.memberID || memberId,
              providerName: userWebPage.companyName,
              pharmacyName: selectedCard?.PharmacyName || '',
              pharmacyAddress: selectedCard?.Address || '',
              pharmacyContact: selectedCard?.Phone || '',
              drugName: searchedMedicationName,
              drugPrice: selectedCard
                ? parseFloat(selectedCard?.Price).toFixed(2).toString()
                : 'N/a',
              bin: savingsCard.BIN || rxBIN,
              group: savingsCard.groupID || rxGroup,
              phone: userData?.phone || '',
              PCN: savingsCard.PCN || 'SC1',
              fileData: imgData,
            });
          } else {
            await SearchService.SendCardEmail({
              name: userData?.firstName || '',
              memberId: savingsCard.memberID || memberId,
              providerName: userWebPage.companyName,
              pharmacyName: selectedCard?.PharmacyName || '',
              pharmacyAddress: selectedCard?.Address || '',
              pharmacyContact: selectedCard?.Phone || '',
              drugName: searchedMedicationName,
              drugPrice: selectedCard
                ? parseFloat(selectedCard?.Price).toFixed(2).toString()
                : 'N/a',
              bin: savingsCard.BIN || rxBIN,
              group: savingsCard.groupID || rxGroup,
              email: userData?.email || '',
              PCN: savingsCard.PCN || 'SC1',
              fileData: imgData,
            });
          }
          toast.success('Discount card sent');
          console.log(' Discount card sent ');
        } catch (e) {
          // data = { statusCode: '0' };
          toast.error('Error sending the Discount card');
          setSendingDiscountCard({ isText, loading: false });
        }

        setSendingDiscountCard({ isText, loading: false });
      })
      .catch((error: any) => {
        console.error('Error capturing image:', error);
        setSendingDiscountCard({ isText, loading: false });
      });
  };

  const getActionButtons = () => {
    return (
      <Grid>
        <Grid.Row centered columns={3}>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <Button
              loading={sendingDiscountCard.loading && sendingDiscountCard.isText}
              onClick={() => onSendDiscountCard(true)}
              fluid
              style={{ backgroundColor: userWebPage.actionButtonColor }}
              color='orange'
            >
              Text
            </Button>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <Button
              style={{ backgroundColor: userWebPage.actionButtonColor }}
              loading={sendingDiscountCard.loading && !sendingDiscountCard.isText}
              onClick={() => onSendDiscountCard(false)}
              fluid
              color='orange'
            >
              Email
            </Button>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <Button
              style={{ backgroundColor: userWebPage.actionButtonColor }}
              basic
              onClick={handlerPrintClick}
              color='blue'
              fluid
            >
              Print
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const getCardTemplate = () => {
    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <div>
              {/* <button onClick={downloadImage}>Download Image</button> */}
              <div>
                <DiscountCardFrontTemplate
                  logoBase64={defaultLogo}
                  title={userWebPage.savingsCard.savingCardTitle}
                  backgroundFillColor={userWebPage.savingsCard.backgroundColor}
                  detailFillColor={userWebPage.savingsCard.secundaryColor}
                  lightTextColor={userWebPage.savingsCard.lightTextColor}
                  strongTextColor={userWebPage.savingsCard.strongTextColor}
                  GRP={rxGroup}
                  PCN={'SC1'}
                  subdomain={userWebPage.subDomainName}
                  userWebPage={userWebPage}
                />
              </div>
              {/* eslint-disable-next-line */}
              <div style={{ textAlign: 'center', marginTop: '0px' }}>
                {/* eslint-disable-next-line */}
                <a href='#' onClick={downloadImage}>
                  Download Card
                </a>{' '}
              </div>
              {/* <Button as="a" onClick={downloadImage}>Download Card</Button> */}
            </div>
            {/* <DiscountCardBackTemplate
                logoBase64={userWebPage.savingsCard.cardLogoURL || undefined}
                title={userWebPage.savingsCard.savingCardTitle}
                backgroundFillColor={userWebPage.savingsCard.backgroundColor}
                detailFillColor={userWebPage.savingsCard.secundaryColor}
                lightTextColor={userWebPage.savingsCard.lightTextColor}
                strongTextColor={userWebPage.savingsCard.strongTextColor}
              ></DiscountCardBackTemplate> */}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8} className='disclaimer'>
            {DisclaimerContent()}
          </Grid.Column>
        </Grid.Row>
        <div style={{ width: '1px', height: '1px', overflow: 'hidden' }}>
          {/* <div> */}
          <div ref={imageRef} id='card'>
            <DiscountCardFrontTemplateForDownload
              logoBase64={defaultLogo}
              title={userWebPage.savingsCard.savingCardTitle}
              backgroundFillColor={userWebPage.savingsCard.backgroundColor}
              detailFillColor={userWebPage.savingsCard.secundaryColor}
              lightTextColor={userWebPage.savingsCard.lightTextColor}
              strongTextColor={userWebPage.savingsCard.strongTextColor}
              GRP={rxGroup}
              PCN={'SC1'}
              subdomain={userWebPage.subDomainName}
              userWebPage={userWebPage}
            />
          </div>
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
      </Grid>
    );
  };

  return (
    <>
      <ModalContainer>
        <Rodal visible={show} onClose={onModalClose}>
          <Transition visible={!showGetCardOptions} animation='slide down' duration={500}>
            <div className='formContainer'>
              <Header as='h2'>Get card</Header>
              <Form onSubmit={onSubmit}>
                <Form.Input
                  error={errors.firstName != null}
                  onChange={onFormValueChanged}
                  name='firstName'
                  placeholder='First name'
                />
                <Form.Input
                  error={errors.lastName != null}
                  onChange={onFormValueChanged}
                  name='lastName'
                  placeholder='Last name'
                />
                <Form.Input
                  error={errors.email != null}
                  onChange={onFormValueChanged}
                  name='email'
                  type='email'
                  placeholder='Email'
                />
                <Form.Input
                  error={errors.phone != null}
                  onChange={onFormValueChanged}
                  maxLength={10}
                  name='phone'
                  type='tel'
                  placeholder='Phone'
                />
                <Form.Select
                  error={errors.state != null}
                  name='state'
                  onChange={onStateChanged}
                  options={options}
                  placeholder='State'
                />
                {hasErrors && <p>Please check that the fields are entered correctly.</p>}
                <Button
                  style={{ backgroundColor: userWebPage.actionButtonColor }}
                  fluid
                  type='submit'
                >
                  Get Your Card
                </Button>
              </Form>
            </div>
          </Transition>
          <Transition visible={showGetCardOptions} animation='slide up' duration={500}>
            <div className='getYourCardContainer'>
              <Header as='h2'>Get your card</Header>
              <Responsive {...Responsive.onlyMobile}>
                {getActionButtons()}
                {getCardTemplate()}
              </Responsive>
              <Responsive {...Responsive.onlyTablet}>
                {getActionButtons()}
                {getCardTemplate()}
              </Responsive>
              <Responsive {...Responsive.onlyComputer}>
                {getCardTemplate()}
                {getActionButtons()}
              </Responsive>
            </div>
          </Transition>
        </Rodal>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </ModalContainer>
      <Rodal visible={showPrintCard} onClose={onModalClose} className='print-rodal'>
        <Segment className='print-preparing'>
          <Dimmer active>
            <Loader>Preparing Print</Loader>
          </Dimmer>
        </Segment>
        <Transition visible={showPrintCard} animation='slide up' duration={500}>
          <ComponentToPrint
            savingsCardStyles={userWebPage.savingsCard}
            ref={toPrintComponentRef}
            selectedCard={selectedCard}
            rxBIN={rxBIN}
            rxGroup={rxGroup}
            medicationName={medicationFullName}
            userData={userData}
            subDomainName={userWebPage.subDomainName}
          />
        </Transition>
      </Rodal>
      <Rodal visible={showDownloadCard} className='print-rodal'>
        <Segment className='print-preparing'>
          <Dimmer active>
            <Loader>Preparing card for download</Loader>
          </Dimmer>
        </Segment>
      </Rodal>
    </>
  );
};

export default GetCardModal;
