import { uniqueId } from 'lodash';
const LAST_VISIT = '_last_visit';

declare const window: any;

export const getLocalValue = (key: string) => {
  const localValue = window.localStorage.getItem(key);
  if (localValue === 'undefined' || localValue === undefined || localValue === null) {
    return null;
  }
  return localValue;
};

export const normalizeOption = (text: string, value: any) => ({
  key: uniqueId(text),
  text,
  value, 
});

export const isMobileDevice = {
  iOS: () => {
    return !!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);
  },
  android: () => {
    return !!navigator.userAgent && /Android/.test(navigator.userAgent);
  },
};

/**
 * Returns the last visit date
 * @export
 * @returns {number} the timespan value
 */
export const getLastVisit = () => {
  // tslint:disable-next-line: radix
  return parseInt(getLocalValue(LAST_VISIT));
};

/**
 * Save the last visit date to easy access later
 * @export
 * @param {number} timespan the timespan value
 */
export const saveLastVisit = (timespan: any) => {
  window.localStorage.setItem(LAST_VISIT, timespan);
};
