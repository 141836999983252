import React from 'react';
import { FooterContainer } from './styled';
import { Menu, Grid } from 'semantic-ui-react';
// import EHNIsoTrans from 'assets/logos/ehnisoTrans.png';
// import MailIcon from 'assets/icons/mail.svg';
// import CallIcon from 'assets/icons/phone.svg';

const Footer = () => {
  // const currentDate = new Date();
  return (
    <FooterContainer>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Menu stackable borderless position='left'>
              {/* <Menu.Item>
                <Grid columns={1} textAlign='left'>
                  <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                      <Header as='h3' image>
                        <Image className='logo' src={EHNIsoTrans} />
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Menu.Item> */}

              {/* <Grid textAlign='left'>
                <Grid.Row columns={3} verticalAlign='middle'>
                  <Grid.Column></Grid.Column>
                  <Grid.Column>
                    <Header as='h3' image>
                      <Grid textAlign='left'>
                        <Grid.Row columns={2}>
                          <Grid.Column mobile={4} computer={4}>
                            <Image src={CallIcon} className='small' />
                          </Grid.Column>
                          <Grid.Column mobile={8} computer={12}>
                            <Header.Subheader>855-798-2538</Header.Subheader>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Header>
                  </Grid.Column>

                  <Grid.Column>
                    <Header as='h3' image>
                      <Grid textAlign='left'>
                        <Grid.Row columns={2}>
                          <Grid.Column verticalAlign='middle' mobile={4} computer={4}>
                            <Image src={MailIcon} className='small' />
                          </Grid.Column>
                          <Grid.Column verticalAlign='middle' mobile={8} computer={12}>
                            <Header.Subheader>info@amedicarx.com</Header.Subheader>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid> */}
            </Menu>
          </Grid.Column>
        </Grid.Row>
        {/* <Grid.Row columns={1} verticalAlign='middle'>
          <Grid.Column>
            <Header as='h3' className='copyright'>
              <Grid textAlign='center'>
                <Grid.Row columns={1} verticalAlign='middle'>
                  <Grid.Column className='disclaimer'>
                    <b>DISCOUNT ONLY - NOT INSURANCE.</b> Discounts are available exclusively
                    through participating pharmacies. The range of the discounts will vary depending
                    on the type of prescription and the pharmacy chosen. This program does not make
                    payments directly to pharmacies. Members are required to pay for all
                    prescription purchases. Cannot be used in conjunction with insurance. You may
                    contact customer care anytime with questions or concerns, to cancel your
                    registration, or to obtain further information. This program is administered by
                    Medical Security Card Company, LLC, Tucson, AZ.
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} verticalAlign='middle'>
                  <Grid.Column className='copyright-p'>
                    © {currentDate.getFullYear()} AmedicaRx | All Rights Reserved
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
          </Grid.Column>
        </Grid.Row> */}
      </Grid>
    </FooterContainer>
  );
};
export default Footer;
