import SearchMedsAPIService from './SearchMedsAPIService';

export type SearchMedicationType = {
  s: string;
  apiDiff: string;
};

export type GetMedicationDetailsType = {
  ndc?: string;
  zip: string;
  drugName?: string;
  qty?: number;
};

export type GetDiscountCardDetailsType = {
  ndc?: string;
  gsn?: string;
  qty?: number;
  zip: string;
  drugName?: string;
  apiDiff: string;
};

export type DiscountCardItem = {
  PharmacyName: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  Phone: string;
  Price: string;
  NCPDP: string;
};

export type SendEmailTextRequestBody = {
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  State: string;
  DrugName: string;
  PharmacyName: string;
  Address: string;
  ContactNumber: string;
  Price: string;
};

export type CardData = {
  name: string;
  providerName: string;
  memberId: string;
  group: string;
  bin: string;
  PCN: string;
  pharmacyName: string;
  pharmacyAddress: string;
  pharmacyContact: string;
  drugName: string;
  drugPrice: string;
  fileData: string;
};

export type EmailData = CardData & {
  email: string;
};

export type TextData = CardData & {
  phone: string;
};

export class SearchService {
  static async SearchMedication(payload: SearchMedicationType): Promise<any> {
    return await SearchMedsAPIService.getData('/ss/searchMedication', payload);
  }
  static async GetMedicationDetails(payload: GetMedicationDetailsType): Promise<any> {
    return await SearchMedsAPIService.getData('/ss/medicationDetails', payload);
  }
  static async GetDiscountCardDetail(payload: GetDiscountCardDetailsType): Promise<any> {
    return await SearchMedsAPIService.getData('/ss/discountCardDetails', payload);
  }
  // static async SendCardEmail(payload: EmailData): Promise<any> {
  //   return await SearchMedsAPIService.getData('/sendCardEmail', payload);
  // }
  static async SendCardEmail(payload: EmailData): Promise<any> {
    return await SearchMedsAPIService.postData('/sendCardEmail', payload);
  }
  static async SendCardText(payload: TextData): Promise<any> {
    return await SearchMedsAPIService.postData('/sendText', payload);
  }
  static async SendEmailText(payload: SendEmailTextRequestBody, isText = false): Promise<any> {
    return await SearchMedsAPIService.postData(
      isText ? '/sendEmailOrText?text=true' : '/sendEmailOrText',
      payload,
    );
  }
}
