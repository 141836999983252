import React from 'react';
import { Header } from 'semantic-ui-react';
import { FrequentlyAskedQuestions } from './styled';

const FAQ = () => {
  return (
    <>
      <FrequentlyAskedQuestions>
        <Header as='h2'> Frequently Asked Questions (FAQs)</Header>
        <br />

        <Header as='h3' image>
          <strong style={{ color: '#1C5CFD' }}>01</strong> Where can this be used? a. 35,000
          locations, including independent pharmacies.
        </Header>

        <Header as='h3' image>
          <strong style={{ color: '#1C5CFD' }}>02</strong> Can I only use the card once? a. You can
          use the card at different locations an unlimited number of times. Some pharmacies will
          even save the card in their system for you, but you should keep it on you in case they
          don’t.
        </Header>

        <Header as='h3' image>
          <strong style={{ color: '#1C5CFD' }}>03</strong> Do I have to sign up for this? a. No. You
          simply need to show the card at the pharmacy.
        </Header>

        <Header as='h3' image>
          <strong style={{ color: '#1C5CFD' }}>04</strong> What drugs can this be used for? a. Our
          cards can be used for over 10,000 generic and brand name drugs. You can use our search
          engine to see if your drug is covered.
        </Header>

        <Header as='h3' image>
          <strong style={{ color: '#1C5CFD' }}>05</strong>Which pharmacy has the lowest price for my
          drug? a. Compare pricingon our homepage. You can see which drug prices are offered at
          locations near you.
        </Header>

        <Header as='h3' image>
          <strong style={{ color: '#1C5CFD' }}>06</strong>Do you sell my data? a. No, we do not sell
          your data to third-party companies.
        </Header>

        <Header as='h3' image>
          <strong style={{ color: '#1C5CFD' }}>07</strong> Who can use the card? a. Anyone can use
          the card. The same card can even be used for multiple people. Also, don’t forget that your
          pets can benefit from the savings program as well.
        </Header>

        <Header as='h3' image>
          <strong style={{ color: '#1C5CFD' }}>08</strong> This seems like a great program. How can
          I share this with people? a. AmedicaRx offers free white-labeling capabilities that enable
          you to send a card that is customized with your brand and logo to whoever you want. You
          can create your own card here. AmedicaRx offers physical cards as well as text messaging
          campaigns to send to your group. Any sort of industry can benefit from the discount card.
        </Header>

        <Header as='h3' image>
          <strong style={{ color: '#1C5CFD' }}>09</strong> Can this beat my insurance? a. Yes, our
          savings cards can beat your insurance. Do a quick search here to see if we have your
          coverage beat.
        </Header>

        <Header as='h3' image>
          <strong style={{ color: '#1C5CFD' }}>10</strong> If I have questions, who can I call? a.
          On our card you will see our customer support number that you can call with any additional
          questions. i. (833) 901-4747
        </Header>
      </FrequentlyAskedQuestions>
    </>
  );
};

export default FAQ;
