import React, { useContext, useEffect, useState } from 'react';
import { SearchDetailsContainer } from './styled';
import { AppContext } from 'containers/App/context';
import { Container, Header, Grid, Dropdown, Loader } from 'semantic-ui-react';
import { useHistory } from 'react-router';
// import { get, update } from 'lodash';
import { SearchService, DiscountCardItem } from 'services/SearchService';
import DiscountCardList from 'components/DiscountCardList';
import GetCardModal from 'components/GetCardModal';
import { normalizeOption } from 'shared/utils';
import { toast, ToastContainer } from 'react-toastify';

const SearchDetails = () => {
  const { medicationDetails, searchedZipCode, userWebPage } = useContext<any>(AppContext);
  const [availableDrugForms, setAvailableDrugForms] = useState<any[]>([]);
  const [availableDosages, setAvailableDosages] = useState<any[]>([]);
  const [quantityOptions, setQuantityOptions] = useState<any[]>([]);
  const [selectedDrugForm, setSelectedDrugForm] = useState<any>({});
  const [selectedDosage, setSelectedDosage] = useState<any>({});
  const [selectedDosageTitle, setSelectedDosageTitle] = useState<any>('');
  const [selectedQuantity, setSelectedQuantity] = useState<any>({});
  const [discountCardList, setDiscountCardList] = useState<DiscountCardItem[]>(
    medicationDetails.Drugs,
  );
  const [selectedDiscountCard, setSelectedDiscountCard] = useState<DiscountCardItem>();
  const [showGetCardModal, setShowGetCardModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [rxGroup, setRxGroup] = useState('');
  // const [rxBIN, setRxBIN] = useState('');
  const rxGroup = userWebPage.savingsCard.groupID || '218438';
  const rxBIN = '610378';
  const history = useHistory();

  useEffect(() => {
    if (!medicationDetails || !medicationDetails.DefaultKey) {
      // if (!medicationDetails) {
      history.replace('/');
    }
  }, [history, medicationDetails]);

  const updateData = (data: any) => {
    if (data.Data) {
      const response: any = data.Data[0].response;
      // const dragName: any = response.Name;
      const forms = response.Values;
      const normalizeForms: any = forms.map((form: any) => normalizeOption(form.Form, form));
      setAvailableDrugForms(normalizeForms);
      const selectedForm: any = forms[0];
      setSelectedDrugForm(selectedForm);

      const dosae: any = selectedForm.Values;
      const normalizeDosae: any = dosae.map((st: any) => normalizeOption(st.Dosage, st));
      // const firstElementStrengths: any = normalizeDosae;
      setAvailableDosages(normalizeDosae);
      const selectedStrength: any = dosae[0];
      setSelectedDosage(selectedStrength);
      setSelectedDosageTitle(selectedStrength.Dosage);

      const quantity = selectedStrength.Values;
      const normalizeQuantity = quantity.map((qt: any) => normalizeOption(qt.Quantity, qt));
      setQuantityOptions(normalizeQuantity);
      setSelectedQuantity(quantity[0]);
    }
  };
  const updateListing = (ndc: string, qty?: number) => {
    // const fillDrugForms = (results: any) => {
    //   if (results) {
    //     updateData(results);
    //   }
    // };

    const getDiscountCardDetails = async () => {
      setLoading(true);
      try {
        const { data: result } = await SearchService.GetDiscountCardDetail({
          ndc: ndc,
          zip: searchedZipCode,
          qty: qty,
          drugName: medicationDetails.DefaultKey[0].SeoName,
          apiDiff: userWebPage.savingsCard.apiDiff,
        });
        setDiscountCardList(result.Drugs);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(
          'Error getting your drug data, please try a different drug name or brand. Please contact us if this persists.',
        );
      }
      // setRxGroup(result[0].RxGroup);
      // setRxBIN(result[0].RxBIN);
    };

    if (
      selectedQuantity &&
      selectedDosage &&
      medicationDetails
      // && medicationDetails.DrugInfo
      // && medicationDetails.DrugInfo.NDC
    ) {
      // if (ndc !== medicationDetails.DrugInfo.NDC || qty !== selectedQuantity.Quantity) {
      // if (ndc !== medicationDetails.DrugInfo.NDC || qty !== selectedQuantity.Quantity) {
      //   getDiscountCardDetails();
      // } else {
      //   fillDrugForms(medicationDetails);
      // }
      getDiscountCardDetails();
    }
  };
  useEffect(() => {
    const initDrugForms = () => {
      updateData(medicationDetails);
    };
    console.log(medicationDetails);
    initDrugForms();
  }, [medicationDetails]);

  useEffect(() => {
    if (!discountCardList) {
      setDiscountCardList(medicationDetails.Drugs);
    }
  }, [discountCardList, medicationDetails.Drugs]);

  useEffect(() => {
    setDiscountCardList(medicationDetails.Drugs);
  }, [medicationDetails.Drugs]);

  const onDrugFormChanged = (_: any, selection: any) => {
    const { value } = selection;
    setSelectedDrugForm(value);

    const dosae: any = value.Values;
    const normalizeDosae: any = dosae.map((st: any) => normalizeOption(st.Dosage, st));
    // const firstElementStrengths: any = normalizeDosae;
    setAvailableDosages(normalizeDosae);
    const selectedStrength: any = dosae[0];
    setSelectedDosage(selectedStrength);
    setSelectedDosageTitle(selectedStrength.Dosage);

    const quantity = selectedStrength.Values;
    const normalizeQuantity = quantity.map((qt: any) => normalizeOption(qt.Quantity, qt));
    setQuantityOptions(normalizeQuantity);
    setSelectedQuantity(quantity[0]);
    updateListing(quantity[0].Value.NDC, quantity[0].Value.Quantity);
  };

  const onDosagesChanged = (_: any, selection: any) => {
    const { value } = selection;
    setSelectedDosageTitle(value.Dosage);
    setSelectedDosage(value);
    console.log(value.NDC);
    const quantity = value.Values;
    const normalizeQuantity = quantity.map((qt: any) => normalizeOption(qt.Quantity, qt));
    setQuantityOptions(normalizeQuantity);
    setSelectedQuantity(quantity[0]);
    updateListing(quantity[0].Value.NDC, quantity[0].Value.Quantity);
  };

  const onQuantityChanged = (_: any, selection: any) => {
    const { value } = selection;
    setSelectedQuantity(value);
    updateListing(value.Value.NDC, value.Value.Quantity);
  };

  const onShowGetCard = (item: DiscountCardItem) => {
    setSelectedDiscountCard(item);
    setShowGetCardModal(true);
  };

  console.log('************************* medicationDetails ****************');
  console.log(medicationDetails);
  const medicationPrimaryName = ` ${
    medicationDetails?.DefaultKey?.length ? medicationDetails?.DefaultKey[0].Name : 'N/A'
  }`;
  const medicationSubName = `${selectedDrugForm?.Form} - ${selectedDosageTitle} - ${selectedQuantity?.Quantity} qty`;

  return (
    <SearchDetailsContainer>
      <GetCardModal
        userWebPage={userWebPage}
        medicationFullName={medicationPrimaryName}
        selectedCard={selectedDiscountCard}
        rxGroup={rxGroup}
        rxBIN={rxBIN}
        show={showGetCardModal}
        onClose={() => setShowGetCardModal(false)}
      />
      <Container>
        {!loading ? (
          <>
            <br></br>
            <Header textAlign='center' as='h1'>
              {medicationPrimaryName}
            </Header>
            <Header textAlign='center' as='h3'>
              {medicationSubName}
            </Header>
            <Header as='h5' textAlign='center' style={{ opacity: 1 }}>
              Customize the dosage and quantity:
            </Header>
            <Grid className='filter-grid'>
              <Grid.Row columns={3}>
                <Grid.Column mobile={16} computer={5}>
                  {availableDrugForms && availableDrugForms.length > 0 && (
                    <Dropdown
                      fluid
                      onChange={onDrugFormChanged}
                      selection
                      options={availableDrugForms}
                      value={selectedDrugForm}
                      // defaultValue={selectedDrugForm.value}
                    />
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} computer={5}>
                  {availableDosages && availableDosages.length > 0 && (
                    <Dropdown
                      fluid
                      onChange={onDosagesChanged}
                      selection
                      options={availableDosages}
                      // defaultValue={selectedDosage.value}
                      value={selectedDosage}
                    />
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} computer={5}>
                  {quantityOptions && quantityOptions.length > 0 && (
                    <Dropdown
                      fluid
                      onChange={onQuantityChanged}
                      selection
                      options={quantityOptions}
                      // defaultValue={selectedQuantity.value}
                      value={selectedQuantity}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {discountCardList && (
              <DiscountCardList
                userWebPage={userWebPage}
                onShowGetCard={onShowGetCard}
                discountCardList={discountCardList}
              />
            )}
          </>
        ) : (
          <Loader active inline='centered' />
        )}
      </Container>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </SearchDetailsContainer>
  );
};

export default SearchDetails;
