import styled from 'styled-components';
import { colors } from 'shared/theme';

export const SavingCardTemplateContainer = styled.div`
  width: 400px;
  height: 550px;
  margin: 10px auto;
  background: transparent;
`;

export const HowItWorksContainer = styled.div`
  position: relative;
  width: 100%;
  background: #fbfbfb;
  border-radius: 20px;
  padding: 40px;
  .sub.header.primary-c {
    max-width: 400px;
    margin: 20px auto !important;
    align-self: center;
  }
  .sub.header.primary-s {
    max-width: 520px;
    margin: 20px auto !important;
    align-self: center;
  }
  h2.ui.header {
    text-align: center;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: -0.01em;
    color: ${colors.basicText};
  }
  h3.ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: ${colors.basicText};
    margin-bottom: 15px;
  }
  h3.ui.header .sub.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-top: 15px;
  }
  .ui.grid {
    /* margin-bottom: 180px; */
  }
  .ui.header > .image:not(.icon),
  .ui.header > img {
    margin-bottom: 30px;
    width: 240px;
  }

  img.ui.image.partners-mobile {
    display: none;
  }

  @media (max-width: 767px) {
    img.ui.image.partners {
      display: none;
    }
    img.ui.image.partners-mobile {
      display: block;
      width: 100%;
      margin-top: 50px;
    }
  }
`;

export const BlueBgContainer = styled.div`
  background: linear-gradient(0deg, #d7e9ff, #d7e9ff), #ffffff;
  border-radius: 10px;
  padding: 50px;
  .ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.01em;
  }

  h1.ui.header {
    color: #1c7cec;
    font-size: 32px;
    line-height: 37px;
    max-width: 444px;
  }
  h2.ui.header {
    font-size: 16px;
    line-height: 22px;
    color: #1a3a61;
    opacity: 0.8;
    max-width: 580px;
  }

  h3.ui.header {
    font-size: 16px;
    line-height: 22px;
    color: #1a3a61;
  }
  .sub.header {
    max-width: 490px;
  }
  @media (max-width: 767px) {
    padding: 30px;
    h1.ui.header {
      font-size: 25px;
      line-height: 25px;
    }
    h2.ui.header {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const FactsCard = styled.div`
  background: #f6f7f9;
  border-radius: 10px;
  padding: 50px 74px;
  min-height: 420px;
  h3.ui.header {
    font-weight: bold;
  }
  @media (max-width: 767px) {
    padding: 15px;
    min-height: auto;
    margin: 10px 0;
  }
`;

export const Frequentlycard = styled.div`
width: 100%;
background: #F4F7FF;
.frequently-content{

}
.frequently-col{
  display: flex;
  align-items: center;
  padding: 4% 20%;
}
.frequently-text{
  text-align: left;
}
.frequently-asked{
  font-size: 26px; 
  font-weight: 600; 
  color:#183A60; 
  margin-bottom: 15px
}
.frequently-question{
  margin-bottom: 10px; 
  color:#28323E;
}
.frequently-common{
  margin-bottom: 0px; 
  color:#28323E;
}
.frequently-img{
  height: 9rem; 
  margin-left: 12rem;
}
.frequently-button{
  background:#F4F7FF;
  border: none;
  width: 100%;
  &:hover{
    background-color:#F4F7FF;
  }
}
@media (max-width: 767px) {
  .frequently-button{
    padding-left: 0px;
    padding-right: 0px;
  }
  .frequently-col{
    display: flex;
    padding: 10% 0%;
  }
  .frequently-img{
   height: 7rem;
   margin-left: 0px;
   position: relative;
   bottom:4.5rem;
  }
  .questions{
    display: block;
  }
}
`

export const PrescriptionBlueBg = styled.div`
  background: linear-gradient(277.84deg, rgba(28, 124, 236, 0.3) 0%, rgba(28, 124, 236, 0) 100%),
    linear-gradient(0deg, #1a3a61, #1a3a61), #ffffff;
  border-radius: 10px;
  padding: 50px;
  .ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.01em;
    color: white;
  }
  h1.ui.header {
    font-size: 32px;
    line-height: 37px;
    max-width: 444px;
  }
  h2.ui.header {
    font-size: 16px;
    line-height: 22px;
    opacity: 0.8;
    color: white;
    max-width: 580px;
  }
  @media (max-width: 767px) {
    h1.ui.header {
      font-size: 25px;
      line-height: 25px;
      max-width: auto;
    }
    h2.ui.header {
      font-size: 14px;
      line-height: 16px;
      max-width: auto;
    }
  }
`;

export const Spacer = styled.div`
  width: 100%;
  height: 100px;
  @media (max-width: 767px) {
    height: 30px;
  }
`;
