import React from 'react';
import { TemplateContainer, TopLeftBG, HeaderContainer } from './styled';
import { Image, Header, List, Grid, Container } from 'semantic-ui-react';
import { defaultLogo } from 'shared/constants';

const DiscountCardBackTemplate = ({
  id = 'SavingCardTemplateBack',
  memberName = 'Example Member',
  GRP = '218438',
  PCN = 'SC1',
  BIN = '610378',
  logoBase64 = defaultLogo,
  title = 'AmedicaRx',
  backgroundFillColor = '#fff',
  detailFillColor = '#e5f1ff',
  strongTextColor = '#1a3a61',
  lightTextColor = '#97a6b8',
  isResponsive = false,
  subdomain = 'www',
  secundaryTitleColor = '#97a6b8',
  secundaryContentColor = '#97a6b8',
}: {
  id?: string;
  memberName?: string;
  GRP?: string;
  PCN?: string;
  BIN?: string;
  logoBase64?: string;
  title?: string;
  backgroundFillColor?: string;
  detailFillColor?: string;
  strongTextColor?: string;
  lightTextColor?: string;
  isResponsive?: boolean;
  subdomain?: string;
  secundaryTitleColor?: string;
  secundaryContentColor?: string;
}) => {
  const savingsCardStyles = {
    backgroundColor: backgroundFillColor,
    secundaryColor: detailFillColor,
    strongTextColor: strongTextColor,
    lightTextColor: lightTextColor,
    secundaryTitleColor: secundaryTitleColor,
    secundaryContentColor: secundaryContentColor,
  };

  return (
    <TemplateContainer id={id} savingsCardStyles={savingsCardStyles} isResponsive={isResponsive}>
      <TopLeftBG savingsCardStyles={savingsCardStyles} isResponsive={isResponsive}>
        {/* <CenterBG savingsCardStyles={savingsCardStyles} isResponsive={isResponsive} /> */}
        <Image id='sc-logo-back' src={logoBase64} size={'small'} />
        <HeaderContainer>
          <Header as='h1'>{title}</Header>
          <Header as='h4'>prescription savings card</Header>
        </HeaderContainer>
      </TopLeftBG>
      <List bulleted relaxed className='defaultText'>
        <List.Item>
          Present this Prescription Savings Card every time you fill or refill a prescription.
        </List.Item>
        <List.Item>
          Even if you have insurance or Medicare, the discount from this card could be less than
          your copay. Use it!
        </List.Item>
        <List.Item>
          Save even more by using this card for your entire family, even your pets!
        </List.Item>
        <List.Item>
          Please visit
          <b>{' ' + subdomain}.amedicarx.com</b> to find out which local pharmacy has the best price
          for your prescription medications.
        </List.Item>
      </List>
      <Grid columns={2} className='contact'>
        <Grid.Row className='noMarginPaddingBottom noPaddingTop disclaimer text-center'>
          <Container className='disclaimer'>
            Please present this card at the Pharmacy with a valid prescription.<br></br>
            {/* Administered by Medical Security Card Company, LLC of Tucson, AZ. */}
          </Container>
        </Grid.Row>
        {/* <Grid.Row className='noMarginPaddingBottom smallPaddingTop'>
          <Grid.Column>
            <Header as='h1'>Cardholder Name</Header>
            <Header as='h3'>{memberName}</Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h4'>Contact if Found</Header>
            <hr className='fillLine'></hr>
          </Grid.Column>
        </Grid.Row> */}
      </Grid>
    </TemplateContainer>
  );
};
export default DiscountCardBackTemplate;
