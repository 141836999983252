import styled from 'styled-components';
import { colors } from 'shared/theme';

export const HeaderContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  scroll-behavior: smooth;
  .btn-actions {
    min-width: 300px;
  }
  .menu-btns {
    width: 100%;
    max-width: 300px;
  }
  .ui.header + p {
    margin-top: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }
  h1.ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 57px;
    letter-spacing: -0.01em;
    color: ${colors.basicText};
    margin: 60px 0;
    .sub.header {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: ${colors.basicText};
      opacity: 0.5;
      margin: 20px 0;
      width: 460px;
    }
  }
  .ui.menu {
    border: none;
    box-shadow: none;
    height: 100px;
    background: transparent;

    .item > img.ui.image {
      width: 200px;
    }
    .ui.basic.button {
      box-shadow: none;
      border: none;
      color: ${colors.basicText} !important;
      font-size: 16px !important;

      box-shadow: none !important;
    }
    .sign-in-btn {
      color: ${colors.mainOrange} !important;
    }
  }

  @media (max-width: 767px) {
    .five.wide.computer.sixteen.wide.mobile.column {
      margin-bottom: 10px;
    }
    h1.ui.header {
      font-size: 25px;
      line-height: 25px;
      .sub.header {
        width: 290px;
      }
    }
    .ui.menu {
      height: 60px;
      .item > img.ui.image {
        width: 123px;
      }
    }
  }
`;
