import React from 'react';
import { TemplateContainer, TopLeftBG, HeaderContainer } from './styled';
import { Image, Header, Grid } from 'semantic-ui-react';
import { defaultLogo } from 'shared/constants';
// import * as htmlToImage from 'html-to-image';

const DiscountCardFrontTemplate = ({
  id = 'SavingCardTemplateFront',
  memberName = '12345678910',
  GRP = '218438',
  PCN = 'SC1',
  BIN = '610378',
  logoBase64 = defaultLogo,
  title = 'AmedicaRx',
  backgroundFillColor = '#fff',
  detailFillColor = '#e5f1ff',
  strongTextColor = '#1a3a61',
  lightTextColor = '#97a6b8',
  isResponsive = false,
  subdomain = 'www',
  secundaryTitleColor = '#000',
  secundaryContentColor = '#1f67ff',
  userWebPage = {},
}: {
  id?: string;
  memberName?: string;
  GRP?: string;
  PCN?: string;
  BIN?: string;
  logoBase64?: string;
  title?: string;
  backgroundFillColor?: string;
  detailFillColor?: string;
  strongTextColor?: string;
  lightTextColor?: string;
  isResponsive?: boolean;
  subdomain?: string;
  secundaryTitleColor?: string;
  secundaryContentColor?: string;
  userWebPage?: any;
}) => {
  const savingsCardStyles = {
    backgroundColor: backgroundFillColor,
    secundaryColor: detailFillColor,
    strongTextColor: strongTextColor,
    lightTextColor: lightTextColor,
    secundaryTitleColor: secundaryTitleColor,
    secundaryContentColor: secundaryContentColor,
  };
  // const imageRef:any = useRef(null);

  // const downloadImage = async () => {
  //   imageRef.current.style.height = '300px';
  //   const dataUrl = await htmlToImage.toPng(imageRef.current);
  //   imageRef.current.removeAttribute('style');
  //   // download image
  //   const link = document.createElement('a');
  //   link.download = "html-to-img.png";
  //   link.href = dataUrl;
  //   link.click();
  //   const canvas = await htmlToImage.toCanvas(imageRef.current);
  //   console.log(canvas.toDataURL());
  // }
  const { savingsCard } = userWebPage;

  return (
    <TemplateContainer id={id} savingsCardStyles={savingsCardStyles} isResponsive={isResponsive}>
      <TopLeftBG savingsCardStyles={savingsCardStyles} isResponsive={isResponsive}>
        <Image className='topLeftImage' id='sc-logo-front' src={logoBase64} size={'small'} />
        <HeaderContainer>
          <Header as='h1'>{title}</Header>
          <Header as='h4'>prescription savings card</Header>
        </HeaderContainer>
      </TopLeftBG>
      <Grid className='memberDetails'>
        <Grid.Row columns={2} className={'noMarginPaddingBottom'}>
          <Grid.Column width={9} className='leftColumn' textAlign='left'>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column width={8}>
                  <Header as='h1' style={{ opacity: 0.5, fontWeight: 'normal' }}>
                    Member ID
                  </Header>
                </Grid.Column>
                <Grid.Column width={9}>
                  <Header as='h2'>{savingsCard?.memberID || '12345678910'}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={8}>
                  <Header as='h1' style={{ opacity: 0.5, fontWeight: 'normal' }}>
                    Customer Care #
                  </Header>
                </Grid.Column>
                <Grid.Column width={9}>
                  <Header as='h2'>{savingsCard?.customerCare || '(833) 901-4747'}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={8}>
                  <Header as='h1' style={{ opacity: 0.5, fontWeight: 'normal' }}>
                    {/* Pharmacy Help # */}{' '}
                  </Header>
                </Grid.Column>
                <Grid.Column width={9}>
                  <Header as='h2'>{/* 800-404-1031 */} </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={5} className='marginBox'>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={5}>
                  <Header className='secundary-title' as='h3' style={{ textAlign: 'right' }}>
                    GRP
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header className='secundary-content' as='h2' style={{ textAlign: 'right' }}>
                    {savingsCard?.groupID || GRP}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={5}>
                  <Header className='secundary-title' as='h3' style={{ textAlign: 'right' }}>
                    BIN
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header className='secundary-content' as='h2' style={{ textAlign: 'right' }}>
                    {savingsCard?.BIN || BIN}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={5}>
                  <Header className='secundary-title' as='h3' style={{ textAlign: 'right' }}>
                    PCN
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header className='secundary-content' as='h2' style={{ textAlign: 'right' }}>
                    {savingsCard?.PCN || PCN}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className='noMarginPaddingBottom text-center'>
          <Grid.Column>
            <Header as='h1'>DISCOUNT ONLY – NOT INSURANCE</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='noMarginPadding bottomUrl'>
          <Grid.Column as='h4'>
            {/* <Header as='h3'> */}
            <strong>{`https://${subdomain}.amedicarx.com/`}</strong>
            {/* </Header> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </TemplateContainer>
  );
};
export default DiscountCardFrontTemplate;
