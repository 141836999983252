import React from 'react';
import { Frequentlycard, HowItWorksContainer, Spacer } from './styled';
import { Button, Grid, Header, Image, Label } from 'semantic-ui-react';
import StepOneIcon from 'assets/icons/hiw01.svg';
import StepTwoIcon from 'assets/icons/hiw02.svg';
import StepThreeIcon from 'assets/icons/hiw03.svg';
import cardsDemo from 'assets/icons/cardsdemo.svg';
import arrowImg from 'assets/icons/arrow-down.svg';
import ContactForm from 'components/ContactForm';

const HowItWorks = () => {
  return (
    <>
      <HowItWorksContainer>
        <Header as='h2'>How it works</Header>
        <Grid doubling textAlign='center' centered>
          <Grid.Row columns={3} centered verticalAlign='middle'>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Header as='h3' image>
                <Image src={StepOneIcon} size='large' />
                <br />
                <strong style={{ color: '#1C5CFD' }}>01</strong> Private labeled savings program
                <Header.Subheader>
                  Create a customized savings program in less than 5 minutes that is specific to
                  your brand. End-users will immediately be able to use your brand to save up to 80%
                  on prescription medications at over 60,000 pharmacies nationwide.
                </Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Header as='h3' image>
                <Image src={StepTwoIcon} size='large' />
                <br />
                <strong style={{ color: '#1C5CFD' }}>02</strong> Targeted messaging campaigns
                <Header.Subheader>
                  An AmedicaRx messaging campaign will promote your program and increase medication
                  adherence by motivating end users to fill their prescriptions timely and take them
                  properly.
                </Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Header as='h3' image>
                <Image src={StepThreeIcon} size='large' />
                <br />
                <strong style={{ color: '#1C5CFD' }}>03</strong> Analytics and reporting
                <Header.Subheader>
                  Using data capture from your prescription savings program, we provide your group
                  with monthly reports showing the activity of each group along with top performing
                  pharmacies and medications.
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Frequentlycard>
            <Button className='frequently-button' as='a' href='/faq'>
              <Grid.Row columns={1} className='frequently-content'>
                <Grid.Column className='frequently-col'>
                  <div className='frequently-text'>
                    <p className='frequently-asked'>
                      Frequently <br />
                      Asked <span className='questions'>Questions</span>
                    </p>
                    <p className='frequently-question'>Do You have Questions?</p>
                    <p className='frequently-common'>
                      You'll find answers here to the most{' '}
                      <span className='questions'>common questions you may</span> <br /> have on
                      AmedicaRx
                    </p>
                  </div>
                  <img src={arrowImg} alt='' className='frequently-img' />
                </Grid.Column>
              </Grid.Row>
            </Button>
          </Frequentlycard>

          <Grid.Row columns={2}>
            <Grid.Column mobile={16} computer={8}>
              <Image src={cardsDemo} />
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <Header as='h3' image>
                Any group can benefit from a branded savings program.
                <Header.Subheader>
                  Simply tell your population to show their savings card at their local pharmacy to
                  receive savings on prescription medications. The pharmacy can even store the card
                  for future use.
                </Header.Subheader>
                <Header textAlign='left' as={'h5'}>
                  OUR PROGRAM IS IDEAL FOR:
                </Header>
                <Label color='yellow'>HEALTH CENTER (FQHC’S)</Label>
                <Label color='yellow'>self insured employers</Label>
                <Label color='yellow'>businesses</Label>
                <Label color='yellow'>hospital systems</Label>
                <Label color='yellow'>international travelers</Label>
                <Label color='yellow'>charitable organizations</Label>
                <Label color='yellow'>universities</Label>
                <Label color='yellow'>veterinarian offices</Label>
              </Header>
              <Button
                as='a'
                href='https://provider.amedicarx.com/sign-up'
                target='_blank'
                color='blue'
              >
                CREATE YOUR PROGRAM
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Spacer />
      </HowItWorksContainer>
      <ContactForm />
    </>
  );
};

export default HowItWorks;
