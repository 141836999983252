import { colors } from 'shared/theme';
import styled from 'styled-components';

export const ContactFormContainer = styled.div`
  .ui.segment {
    background: #1c5cfd;
    border: none;
    box-shadow: none;
    .sub.header {
      max-width: 354px;
      margin: 20px auto !important;
      align-self: center;
      color: white;
    }
    h2.ui.header {
      text-align: center;
      font-family: Ubuntu;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
      letter-spacing: -0.01em;
      color: white;
    }
  }
  .formContainer {
    padding: 40px 120px;
    .ui.form .field .ui.input input,
    .ui.form .fields .field .ui.input input,
    .ui.form textarea {
      width: 100%;
      background: white;
      border-radius: 6px;
      min-height: 50px;
      border: none;
      margin: 10px 0;
    }

    p {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${colors.redError};
    }

    .selection.dropdown {
      background: ${colors.basicBg};
      border: none;
      height: 50px;
      display: flex;
    }
    .dropdown.icon {
      padding-top: 18px;
    }
    .ui.dropdown > .text {
      align-self: center;
    }

    button.ui.fluid.button {
      height: 60px;
      top: 670px;
      background: white;
      border-radius: 6px;
      color: ${colors.mainOrange};
      max-width: 200px;
      margin: 20px auto;
      text-transform: uppercase;
    }
  }

  @media (max-width: 767px) {
    .formContainer {
      padding: 15px;
      .two.column.row {
        padding: 0;
      }
    }
  }
`;
