import styled from 'styled-components';
import { colors } from 'shared/theme';

export const ModalContainer = styled.div`
  .print-pharmacy-details {
    background: white;
    box-shadow: 0px 10px 40px rgba(26, 58, 97, 0.1);
    border-radius: 20px;
    padding: 50px;
    > .row > .column {
      padding: 0 5px !important;
    }
    .ui.grid > .row {
      padding: 0 !important;
    }
  }
  .error.field {
    background: ${colors.basicText};
    border: 1px solid ${colors.redError};
    box-sizing: border-box;
    border-radius: 6px;
  }

  .disclaimer {
    padding-left: 2rem !important;
  }
  .rodal-dialog {
    width: 900px !important;
    height: 570px !important;
    background: #ffffff;
    box-shadow: 0px 20px 40px rgba(26, 58, 97, 0.06);
    border-radius: 10px;
    padding: 25px 60px;

    .ui.header {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
      letter-spacing: -0.01em;
      color: ${colors.basicText};
    }

    h2.ui.header {
      font-size: 32px;
      line-height: 37px;
    }

    .getYourCardContainer {
      // padding: 80px 0px;
      padding: 40px 0px;
      .ui.grid > .row > .column {
        padding: 0;
      }
      p {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: ${colors.basicText};
        opacity: 0.5;
      }
      h3.ui.header {
        font-size: 20px;
        line-height: 23px;
      }
      h4.ui.header {
        font-size: 14px;
        line-height: 16px;
      }

      button.ui.fluid.button {
        height: 64px;
        background: ${colors.mainOrange};
        border-radius: 6px;
        color: white;
        width: 235px;
      }

      button.ui.orange.basic.button {
        box-shadow: 0 0 0 1px ${colors.mainOrange} inset !important;
        color: ${colors.mainOrange};
        height: 64px;
        border-radius: 6px;
        width: 235px;
      }
    }

    .formContainer {
      padding: 40px 120px;
      .ui.form .field .ui.input input,
      .ui.form .fields .field .ui.input input {
        width: auto;
        background: ${colors.basicBg};
        border-radius: 6px;
        height: 50px;
        border: none;
      }

      p {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: ${colors.redError};
      }

      .selection.dropdown {
        background: ${colors.basicBg};
        border: none;
        height: 50px;
        display: flex;
      }
      .dropdown.icon {
        padding-top: 18px;
      }
      .ui.dropdown > .text {
        align-self: center;
      }

      button.ui.fluid.button {
        margin: 20px 0;
        height: 70px;
        top: 670px;
        background: ${colors.mainOrange};
        border-radius: 6px;
        color: white;
      }
    }
  }

  @media (max-width: 767px) {
    .rodal-dialog {
      width: 345px !important;
      height: 533px !important;
      padding: 24px 20px;
      overflow-y: auto;
      overflow-x: hidden;

      .formContainer {
        padding: 0;
      }

      .getYourCardContainer {
        padding: 0px;
        p {
          font-size: 12px;
        }
        button.ui.fluid.button {
          width: 100%;
          margin-bottom: 10px;
        }
        button.ui.orange.basic.button {
          width: 100%;
        }
      }
    }
  }
`;
