import React, { useState, ReactChild, useEffect } from 'react';
import AddToHome from 'shared/AddToHome';
import { isMobileDevice, getLastVisit, saveLastVisit } from 'shared/utils';
import { MAIN_DOMAIN, SUBDOMAIN, USER_SETTINGS_URL } from 'shared/constants';
const AppContext: any = React.createContext({});

type Props = {
  children: ReactChild;
};

export interface UserWebPageType {
  userId?: string;
  userWebPageId?: string;
  webLogo: any;
  webLogoURL?: any;
  title1: string;
  title2: string;
  leftGradientFill: string;
  rightGradientFill: string;
  strongTextColor: string;
  actionButtonColor: string;
  companyName: string;
  subDomainName: string;
  createdAt?: number;
  savingsCard: any;
}

export interface PrescriptionCardType {
  backgroundColor: string;
  cardLogo: string;
  cardLogoURL: string;
  groupID: string;
  BIN: string;
  PCN: string;
  apiDiff: string;
  lightTextColor: string;
  memberID: string;
  savingCardTitle: string;
  secundaryColor: string;
  secundaryContentColor: string;
  secundaryTitleColor: string;
  strongTextColor: string;
}
export const initialWebValues: UserWebPageType = {
  webLogo: '',
  webLogoURL: null,
  title1: 'Shop Prices, Save on Meds',
  title2: '',
  leftGradientFill: '#e5f1ff',
  rightGradientFill: '#034AFD',
  strongTextColor: '#1a3a61',
  actionButtonColor: '#034AFD',
  companyName: 'AmedicaRx',
  subDomainName: 'www',
  savingsCard: {
    backgroundColor: '#FFFFFF',
    cardLogo: '',
    BIN: '',
    PCN: '',
    cardLogoURL: '',
    apiDiff: '',
    lightTextColor: '#a2afbe',
    savingCardTitle: 'AmedicaRx',
    secundaryColor: '#ffffff',
    strongTextColor: '#19375c',
    groupID: '218438',
    memberID: '12345678910',
    secundaryContentColor: '#fffff',
    secundaryTitleColor: '#19375c',
  },
};

const AppProvider = ({ children }: Props) => {
  const [searchedMedicationID, setSearchedMedicationID] = useState();
  const [searchedMedicationName, setSearchedMedicationName] = useState();
  const [searchedZipCode, setSearchedZipCode] = useState();
  const [showSearchDetails, setShowSearchDetails] = useState();
  const [medicationDetails, setMedicationDetails] = useState({});
  const [showAddToHome, setShowAddToHome] = useState(false);
  const [userWebPage, setUserWebPage] = useState<UserWebPageType>(initialWebValues);
  const addToHomeCanBeShowed =
    isMobileDevice.iOS() && !window.matchMedia('(display-mode: standalone)').matches;

  const showAddToHomeMessage = () => {
    setTimeout(() => {
      setShowAddToHome(true);
    }, 5000);
  };
  useEffect(() => {
    const getRemoteConfig = async () => {
      const baseURL = USER_SETTINGS_URL;
      let requestURL = `${baseURL}/get-by/`;
      requestURL += SUBDOMAIN;

      const response = await fetch(requestURL);
      if (response.ok) {
        const { result } = await response.json();
        if (result) {
          console.log('WEBPAGE DATA', result);
          initialWebValues.savingsCard.BIN = result.savingsCard.BIN;
          initialWebValues.savingsCard.PCN = result.savingsCard.PCN;
          initialWebValues.savingsCard.groupID = result.savingsCard.groupID;
          initialWebValues.savingsCard.memberID = result.savingsCard.memberID;
          initialWebValues.savingsCard.apiDiff = result.savingsCard.apiDiff;

          setUserWebPage(initialWebValues);
        } else {
          window.location.href = `https://www.${MAIN_DOMAIN}/`;
        }
      } else {
        console.log('HTTP-Error: ' + response.status);
        window.location.href = `https://www.${MAIN_DOMAIN}/`;
      }
    };
    getRemoteConfig();
  }, []);
  useEffect(() => {
    const checkLastVisit = () => {
      const lastVisitTimespan = getLastVisit();
      if (!lastVisitTimespan) {
        if (addToHomeCanBeShowed) {
          showAddToHomeMessage();
        }
      } else {
        const currentDate = new Date(Date.now());
        const lastVisitDate = new Date(lastVisitTimespan);
        // This allow us to show the Messages if the last visit was more than 2 days ago.
        const validDifference = currentDate.getDate() - lastVisitDate.getDate() >= 2;
        if (validDifference) {
          if (addToHomeCanBeShowed) {
            showAddToHomeMessage();
          }
        }
      }
      saveLastVisit(Date.now());
    };
    checkLastVisit();
  }, [addToHomeCanBeShowed]);

  return (
    <AppContext.Provider
      value={{
        userWebPage,
        searchedMedicationID,
        setSearchedMedicationID,
        searchedZipCode,
        setSearchedZipCode,
        showSearchDetails,
        setShowSearchDetails,
        medicationDetails,
        setMedicationDetails,
        searchedMedicationName,
        setSearchedMedicationName,
      }}
    >
      <AddToHome onClose={() => setShowAddToHome(false)} show={showAddToHome} />
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
