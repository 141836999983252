import React, { useContext, useEffect } from 'react';
import { HomeContainer } from './styled';
import HowItWorks from 'components/HowItWorks';
import { AppContext } from 'containers/App/context';
import { useHistory } from 'react-router';

const Home = () => {
  const { showSearchDetails } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (showSearchDetails) {
      history.push('/search-details');
    }
  }, [showSearchDetails, history]);

  return (
    <HomeContainer>
      <HowItWorks />
    </HomeContainer>
  );
};
export default Home;
