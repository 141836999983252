import RxValetAPIService from './RxValetAPIService';

export type SendEmailTRequestBody = {
  subject: string;
  message: string;
  email: string;
  name: string;
  phone: string;
};

export class EmailService {
  static async SendEmail(payload: SendEmailTRequestBody): Promise<any> {
    return await RxValetAPIService.postData('/sendEmailUsers', payload);
  }
}
