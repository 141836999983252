import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { Switch } from 'react-router';
import Home from 'containers/Home';
import SearchDetails from 'containers/SearchDetails';
// import FAQHeader from 'components/FAQHeader';
import FAQ from 'containers/FAQ';

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/search-details' exact component={SearchDetails} />
        <Route path='/faq' exact component={FAQ} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
